import {statusSuccess} from './status';
import {SET_SELECTED_CLUB,SHOW_VERSION_MISMATCH_POPUP} from '../constants';

export const setSelectedClub = (selectedClub) => {
  return (dispatch) => {
    localStorage.setItem('selectedClub', JSON.stringify(selectedClub));
    dispatch(statusSuccess(SET_SELECTED_CLUB, selectedClub));
  };
};

export const showVersionMismatchPopup = (value) => {
  return async (dispatch) => {
    dispatch(statusSuccess(SHOW_VERSION_MISMATCH_POPUP, value));
  }
}