import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {useDispatch} from 'react-redux';
import {setSelectedClub} from '../../vendor/redux/actions/selected-club';
import {useNavigate} from 'react-router-dom';
import ProfileImageViewer
  from '../../components/_Global/Profile/ProfileImageViewer';
import {UnfoldMoreOutlined} from '@mui/icons-material';
import useUser from '../../hooks/access/useUser';
import useSwitcherClubs from '../../hooks/clubs/useSwitcherClubs';
import {useSelectedClub} from '../../hooks/clubs/useSelectedClub';
import axios from 'axios';
import useIsMobile from '../../hooks/ui/useIsMobile';

function LayoutMainHeaderTopClubSelect({disableDropdown}) {

  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const isMobile = useIsMobile();
  const user = useUser();
  const switcherClubs = useSwitcherClubs();
  const selectedClub = useSelectedClub();

  function handleChangeClub(value) {
    const parsedClubValue = JSON.parse(value);
    axios.defaults.headers.common['x-clubos-club'] = parsedClubValue.id;
    dispatch(setSelectedClub(parsedClubValue));
    navigateTo('/schedule');
  }

  if (selectedClub && switcherClubs?.length > 0) {
    return (
        <Box>
          {
            /*Show club selector for roles other than club staff*/
              user?.userObj?.role !== 'club_staff' &&
              <FormControl size="Medium" fullWidth>
                <Select
                    IconComponent={CustomSelectIcon}
                    renderValue={RenderSelectValue}
                    defaultValue={''}
                    value={JSON.stringify(selectedClub)}
                    onChange={e => handleChangeClub(e.target.value)}
                    MenuProps={{
                      sx: {
                        mt: 1,
                        maxHeight: '650px',
                      },
                    }}
                    sx={{
                      boxShadow: 'none',
                      pointerEvents: disableDropdown? 'none': 'initial',
                      'svg': {
                        display: disableDropdown ? 'none': 'initial'
                      },
                      '.MuiSelect-select': {
                        paddingLeft: '0!important',
                        paddingTop: '0!important',
                        paddingBottom: '0!important',
                        paddingRight: '24px !important',
                      },
                      '.MuiOutlinedInput-notchedOutline': {border: 0},
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                        borderRadius: '0',
                      },
                    }}
                >
                  {
                    switcherClubs.map(club => {
                      const stringifiedValue = JSON.stringify({
                        id: club.clubId,
                        timezone: club.addresses[0].timezone,
                        name: club.name,
                        imageUrl: club.imageUrl,
                        email: club?.email,
                      });
                      return <MenuItem
                          key={club.clubId}
                          value={stringifiedValue}
                      >
                        {club.name}
                      </MenuItem>;
                    })
                  }
                </Select>
              </FormControl>
          }
          {
            /*Show Single Club Details for Club staff*/
              user?.userObj?.role === 'club_staff' &&
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <ProfileImageViewer
                    size={'xs'}
                    imgPath={selectedClub?.imageUrl}
                    placeholderStyle={'shield-icon'}
                />
                <Typography variant="h6">
                    {selectedClub?.name}
                </Typography>
              </Stack>
          }
        </Box>);
  }
}

function RenderSelectValue(value) {
  const obj = JSON.parse(value);
  const isMobile = useIsMobile();
  return (
      <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={2}
      >
        <ProfileImageViewer
            size={'xs'}
            imgPath={obj?.imageUrl}
            placeholderStyle={'shield-icon'}
        />
        {
        !isMobile &&
        <Typography
            variant={'h6'}
            paddingRight={'6px'}
        >
          {obj?.name}
        </Typography>
        }
      </Stack>
  );
}

function CustomSelectIcon(props) {
  const theme = useTheme();
  return (
      <Box
          {...props}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
      >
        <UnfoldMoreOutlined
            color={theme.palette.text.primary}
            sx={{
              height: '16px',
              width: '16px',
            }}
        />
      </Box>

  );
}

export default LayoutMainHeaderTopClubSelect;
