import { membersUpdate_PATCH } from "../vendor/redux/actions/manageMembers";

export const saveLanguageForUserHandler=(adminId,language,cbSuccess,cbFail,setLoading)=>{

    return dispatch=>{

        setLoading(true)

        const body={language}

        dispatch(membersUpdate_PATCH(adminId, body, cbSuccess,cbFail));

    }

}