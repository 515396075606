import {Chip} from '@mui/material';
import useChipStyles from '../../hooks/bookings/useChipStyles';
import {useTranslate} from '@tolgee/react';

function ChipFinancialStatus({status,label}) {
  const {t} = useTranslate();
  const [neutralStyles, successStyles, errorStyles] = useChipStyles('filled');
  let styles;

  switch (status) {
    case 'open':
      styles = successStyles;
      break
    case 'paid':
    styles = successStyles;
    break
    case 'expired':
    styles = errorStyles;
    case 'unpaid':
    styles = errorStyles;
      break;
  case 'failed':
    styles = errorStyles;
      break;
  }

  return (
      <Chip
          size={'small'}
          label={label ?? t(`global.types.transactions.status.${status}`)}
          sx={{
            ...styles,
          }}
      />
  );
}

export default ChipFinancialStatus;
