import {Stack, Typography} from '@mui/material';
import ArtEmptyAntDefault from '../../../assets/art/ArtEmptyAntDefault';
import ArtEmptyAntSimple from '../../../assets/art/ArtEmptyAntSimple';

function Empty({
  description: Description,
  image: Image,
  imageStyle = 'default' || 'simple',
  children,
}) {

  if (!Description) {
    Description = <Typography color={'text.disabled'}>No data</Typography>;
  }

  if (!Image) {
    if (imageStyle === 'default') {
      Image = ArtEmptyAntDefault;
    }
    if (imageStyle === 'simple') {
      Image = ArtEmptyAntSimple;
    }
  }

  return (
      <Stack>
        <Stack
            alignItems={'center'}
            sx={{
              svg: {
                height: '80px',
              },
            }}
        >
          <Image/>
          {Description}
        </Stack>
        {children}
      </Stack>
  );

}

export default Empty;

