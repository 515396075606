import {useTolgee, useTranslate} from '@tolgee/react';
import {Box, IconButton, ListItemIcon, Menu, MenuItem} from '@mui/material';
import useLocale from '../../../hooks/localization/useLocale';
import FlagEnUS from '../../../assets/flags/FlagEnUS';
import FlagNl from '../../../assets/flags/FlagNl';
import {useState} from 'react';
import useUser from '../../../hooks/access/useUser';
import { useSnackbar } from 'notistack';
import { saveLanguageForUserHandler } from '../../../utils/settings';
import { useDispatch } from 'react-redux';

function SiteLanguageSwitcher(props) {

  const {onSuccess} = props

  const tolgee = useTolgee(['language']);
  const {locale, setLocale} = useLocale();
  const {enqueueSnackbar} = useSnackbar();
  const user = useUser();
  const dispatch = useDispatch();
  const {t} = useTranslate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const open = Boolean(anchorEl);

  const editLanguageHandler=(lang)=>{

    dispatch(saveLanguageForUserHandler(user?.userObj?.id,lang,cbSuccess,cbFail,setUpdateLoading))

    function cbSuccess(res) {
      
      // enqueueSnackbar(t('settings.language.alert.update'), {variant: 'success'})
      setUpdateLoading(false);
      onSuccess();
    }

    function cbFail(e) {
      
      const error = e?.response?.data?.data
      
      enqueueSnackbar(error || t('networkError'), {variant: 'error'})
      setUpdateLoading(false);
    
    }

  }

  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handleClickMenuItem(key) {
    tolgee.changeLanguage(key);
    localStorage.setItem('locale', JSON.stringify(key));
    setLocale(key);
    handleCloseMenu();
    if(user){
      editLanguageHandler(key)
    }
  }

  const flags = {
    en: FlagEnUS,
    nl: FlagNl,
    // es: FlagES,
    // fr: FlagFR,
    // it: FlagIT,
  };

  let SelectedFlag = flags[locale];

  const locales = [
    {
      key: 'en',
      label: 'English (US)',
    },
    {
      key: 'nl',
      label: 'Nederlands (NL)',
    },
    // {
    //   key: 'es',
    //   label: 'Español (ES)',
    // },
    // {
    //   key: 'fr',
    //   label: 'Français (FR)',
    // },
    // {
    //   key: 'it',
    //   label: 'Italiano (IT)',
    // },
  ];

  return (
      <Box>
        <IconButton onClick={handleClickOpenMenu} sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          svg: {
            width: '26px',
            height: '26px',
          },
        }}>
          <SelectedFlag/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              // vertical: 'bottom',
              horizontal: 'right',
            }}
        >
          {
            locales.map((item) => {
              return <ListItem item={item}
                               flags={flags}
                               onClick={handleClickMenuItem}/>;
            })
          }
        </Menu>

      </Box>
  );
}

function ListItem({item, flags, onClick}) {
  const Flag = flags[item.key];
  return (
      <MenuItem
          value={item.key}
          onClick={()=>{
            onClick(item.key)
          }}
      >
        <ListItemIcon sx={{
          mr:.5,
          svg:{
            width: "28px",
            height: "28px",
          }
        }}><Flag/></ListItemIcon>
        {item.label}
      </MenuItem>
  );
}

export default SiteLanguageSwitcher;


