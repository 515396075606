import React, { useState } from 'react'
import PageHeader from '../../components/_Global/Page/PageHeader'
import BreadcrumbsStatic from '../../components/_Global/Breadcrumbs/BreadcrumbsStatic'
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import { Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';
import useLocale from '../../hooks/localization/useLocale';
import PageFinancesTransactionsList from './PageFinancesTransactionsList';

function PageFinanceFullData() {
  
    const {t} = useTranslate();
    const {locale} = useLocale();
    const [rangeValue, setRangeValue] = useState([null, null])
    dayjs.locale(locale); 

  return (
    <div>
      <PageHeader 
      breadcrumbsSlot={
        <BreadcrumbsStatic 
        options={[
            {label: t('finances.breadcrumbs.allFinances')}
        ]} 
        />
      }
      actionsSlot={
        <Stack direction={"row"} spacing={2}>
          <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
            <DateRangePicker
              sx={{
                label: {
                  top: "-6px !important",
                },
                input: {
                  padding: "10px 14px !important",
                },
              }}
              value={rangeValue}
              onChange={(e) => setRangeValue(e)}
              
              localeText={{
                start: t("bookings.table.header.startDate"),
                end: t("bookings.table.header.endDate"),
              }}
            />
          </LocalizationProvider>
        </Stack>
      }
      />
      <PageFinancesTransactionsList
      rangeValue={rangeValue}
      loadFullData={true}
      filterType='client'
      paginatonType='client' 
      />
    </div>
  )
}

export default PageFinanceFullData