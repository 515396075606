import * as axios from 'axios';
import {persistor, store} from '../redux/configure-store';
import {RESET_REDUX_STATE, USER_LOGOUT} from '../redux/constants';
import { showVersionMismatchPopup } from '../redux/actions/selected-club';

export default function setupAxios() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.timeout = 30000;
  axios.defaults.headers.common['x-clubos-channel'] = 'CLUBOS-WEB-ADMIN';
  axios.defaults.headers.common['x-clubos-domain'] = process.env.REACT_APP_ENV_DOMAIN;
  axios.defaults.headers.common['version'] =  process.env.REACT_APP_BUILD_VERSION;

  axios.interceptors.request.use(
      (config) => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user?.accessToken) {
          config.headers.Authorization = `Bearer ${user?.accessToken}`;
        }
        return config;
      },
      err => Promise.reject(err),
  );

  axios.interceptors.response.use(
      response => {
        const versionUpdate = response?.headers?.version;
        if(versionUpdate === 'true') {
          store.dispatch(showVersionMismatchPopup(true));
        }
        else {
          store.dispatch(showVersionMismatchPopup(false));
        }
        return response;
      },
      err => {
        if (err.response.status === 401 || err.response.status === 403) {
          const hadMasterAccess = !!localStorage.getItem('masterAccess');
          localStorage.clear();
          if (hadMasterAccess) {
            localStorage.setItem('masterAccess', 'true');
          }
          store.dispatch({type: RESET_REDUX_STATE});
          store.dispatch({type: USER_LOGOUT});
          persistor.purge();
        }
        return Promise.reject(err);
      },
  );

  return axios;

}

