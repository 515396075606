import {statusError, statusLoading, statusSuccess} from './status';
import {
  FINANCIALS_LIST_GET_FAILURE,
  FINANCIALS_LIST_GET_LOADING,
  FINANCIALS_LIST_GET_SUCCESS,
} from '../constants';
import axios from 'axios';

export const financialsList_GET = (
    page, limit,searchTerm, startDate, endDate, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(FINANCIALS_LIST_GET_LOADING));
      const response = await axios.post(
          `/v2/financials/list?&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`,{searchTerm});
      dispatch(statusSuccess(FINANCIALS_LIST_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(FINANCIALS_LIST_GET_FAILURE, e));
    }
  };
};

export const dailyReportList_GET = (
  companyId, clubId, cbSuccess, cbFailure) => {
return async (dispatch) => {
  try {
    dispatch(statusLoading(FINANCIALS_LIST_GET_LOADING));
    const response = await axios.get(
        `reports/all-bookings/${companyId}/${clubId}`);
    dispatch(statusSuccess(FINANCIALS_LIST_GET_SUCCESS, response.data));
    cbSuccess(response);
  } catch (e) {
    cbFailure(e);
    dispatch(statusError(FINANCIALS_LIST_GET_FAILURE, e));
  }
};
};