import {statusError, statusLoading, statusSuccess} from './status';
import {
  ADD_ADMINS_FAILURE,
  ADD_ADMINS_LOADING,
  ADD_ADMINS_SUCCESS,
  GET_ADMINS_FAILURE,
  GET_ADMINS_LOADING,
  GET_ADMINS_SUCCESS,
} from '../constants';
import axios from 'axios';

export const membersListing_GET = (searchQuery, limit, pageNo, cbSuccess,cbFail) => {
  return async (dispatch) => {
    try {
      const createAt = 'createdAt';
      const order = 'DESC';
      dispatch(statusLoading(GET_ADMINS_LOADING));
      const response = await axios.get(
          `auth/admin/list?&searchQuery=${searchQuery}&limit=${limit}&page=${pageNo}&sortBy=${createAt}&orderBy=${order}`);
      dispatch(statusSuccess(GET_ADMINS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(GET_ADMINS_FAILURE, e));
    }
  };
};

export const superAdminsListing_GET = (searchQuery, limit, pageNo, cbSuccess,cbFail) => {
  return async () => {
    try {
      const response = await axios.get(
          `auth/admin/superAdmin?&searchQuery=${searchQuery}&limit=${limit}&page=${pageNo}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const member_GET = (id, cbSuccess,cbFail) => {
  return async (dispatch) => {
    try {

      dispatch(statusLoading(GET_ADMINS_LOADING));
      const response = await axios.get(
          `auth/admin/${id}`);

      dispatch(statusSuccess(GET_ADMINS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(GET_ADMINS_FAILURE, e));
    }
  };
};
export const membersCreate_POST = (body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {

      dispatch(statusLoading(ADD_ADMINS_LOADING));
      const response = await axios.post(
          `v2/auth/admin/create`, body);
        //   &name=${name}&limit=${limit}&page=${pageNo}&sortBy=${createAt}&orderBy=${order}
      dispatch(statusSuccess(ADD_ADMINS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      console.log(e);
      cbFail(e);
      dispatch(statusError(ADD_ADMINS_FAILURE, e));
    }
  };
};

export const membersUpdate_PATCH = (id, body, cbSuccess,cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(ADD_ADMINS_LOADING));
      const response = await axios.patch(
          `auth/admin/${id}`, body);
        //   &name=${name}&limit=${limit}&page=${pageNo}&sortBy=${createAt}&orderBy=${order}
      dispatch(statusSuccess(ADD_ADMINS_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(ADD_ADMINS_FAILURE, e));
    }
  };
};

export const memberStatusUpdate = (props) => {
  
  const {body,setUpdateLoading, cbSuccess,cbFail} = props
  
  return async (dispatch) => {
    try {
      
      setUpdateLoading(true)
      
      const response = await axios.patch(
          `/auth/super-admin-change-admin-status`, body);
      
      cbSuccess(response);

    } catch (e) {
      cbFail(e);
      setUpdateLoading(false)
    }
  };
};