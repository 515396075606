import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSelector,useDispatch } from "react-redux";
import { showVersionMismatchPopup } from "../../../vendor/redux/actions/selected-club";
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

function VersionUpdate() {
    const {t} = useTranslate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
     
    const showVersionMismatch =  useSelector((state) => state.app.showVersionMismatch);
    console.log('showVersionMismatch',showVersionMismatch)
    const dispatch = useDispatch();

    return (
      showVersionMismatch &&  <Box  sx={{
        position: 'fixed',
        bottom: 22,
        left: '50%',
        transform: 'translateX(-50%)',
          zIndex: 111,
          width: isMobile ? '100%' : '600px',
        padding: '20px',
        background: 'rgba(255, 255, 255, 1)',
        borderRadius: '16px',
        boxShadow:
`-1px 6px 14px 0px rgba(74, 69, 69, 0.1),
-4px 26px 26px 0px rgba(74, 69, 69, 0.09),
-8px 58px 35px 0px rgba(74, 69, 69, 0.05),
-14px 103px 42px 0px rgba(74, 69, 69, 0.01),
-22px 162px 46px 0px rgba(74, 69, 69, 0)`,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

      }}>
        <Box sx={{
          background: theme.palette.primary.main,
          width: '42px',
          height: '42px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '18px',
          flexShrink: 0
        }}>
          <RocketLaunchOutlinedIcon sx={{color: '#fff', width: '18px'}} />
        </Box>
        <Typography fontWeight={600} fontSize={'14px'}>{t('version.update')}</Typography>
        <Button sx={{ml: 1}} color="primary"  onClick={()=>{
          dispatch(showVersionMismatchPopup(false));
          setTimeout(()=>{
            window.location.reload()
          },500)
           }}>{ <RefreshOutlinedIcon
            sx={{
              // transition: 'transform 1s linear',
              // transform: isRotating ? 'rotate(360deg)' : '',
              mr:0.5,
            }}
          />} {t('button.reload')}</Button>
  
       </Box>
      
    )
  }
  
  export default VersionUpdate

