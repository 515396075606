import {Box} from '@mui/material';

function ContainerFullWidthNavbar({children,isMobile}) {
  return <Box
      sx={{
        margin: '0 auto',
        maxWidth: '100%',
        width: isMobile?'75vw':'100%',
        padding: !isMobile && '0 24px'
      }}
  >
    {children}
  </Box>;
}

export default ContainerFullWidthNavbar;
