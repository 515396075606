import {Alert, Box, Typography} from '@mui/material';
import {
  LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT,
  LAYOUT_MAIN__HEADER__TOP__HEIGHT,
  LAYOUT_MAIN__PAGE_PADDING_BOTTOM,
} from '../../constants/ui';
import {useEffect, useRef} from 'react';
import SiteFooter from '../../components/_Global/Site/SiteFooter';
import ContainerFullWidth
  from '../../components/_Global/Containers/ContainerFullWidth';
import useHasCapability from '../../hooks/access/useHasCapability';
import { CAPABILITY_UPDATE } from '../../constants/capabilities';
import { MODULE_COMPANY } from '../../constants/modules';
import { useSelector } from 'react-redux';
import { useTranslate } from '@tolgee/react';
import { Link } from 'react-router-dom';
import useIsRole from '../../hooks/access/useIsRole';

function LayoutMainPage({children}) {

  const pageRef = useRef(null);
  const company = useSelector(state => state.app.company);
  const isSuperAdmin = useIsRole('super_admin');
  const {t} = useTranslate();
  const CAN_UPDATE_COMPANY = useHasCapability(CAPABILITY_UPDATE, MODULE_COMPANY);
  const isNotMolieConnected = company?.data?.companyBillings[0]?.status === 'incomplete';

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.addEventListener('scroll', () => {
        document.activeElement.blur();
      });
    }
  }, [pageRef.current]);

  useEffect(()=>{
    document.addEventListener('wheel', ()=>{
      if(document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    })
  },[])
  return (
      <Box

          component={'main'}
          sx={{
            display: 'flex',
            height: `calc(100vh - (${LAYOUT_MAIN__HEADER__TOP__HEIGHT} + ${LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT}))`,
          }}
      >
        <Box
            id={'layoutMain__Page'}
            ref={pageRef}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              overflowY: 'scroll',
              overflowX: 'auto',
              paddingTop: 0,
            }}>
          <ContainerFullWidth>
          {/* {!isSuperAdmin && CAN_UPDATE_COMPANY && isNotMolieConnected &&
              <Box pt={2}>
              <Alert severity='warning'>
                {t('compnay.connectMolie.warning')} <Typography color={'primary'} component={Link} to={'/settings/company/detail'}>{t('company.profileLink')}</Typography> {t('company.connectMolie.warningSubPart')}
                </Alert>
              </Box>
        } */}

            {children}
          </ContainerFullWidth>
          <Box sx={{minHeight: LAYOUT_MAIN__PAGE_PADDING_BOTTOM}}></Box>
          <SiteFooter/>
        </Box>
      </Box>
  );
}

export default LayoutMainPage;
