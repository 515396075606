import {GridFilterDateInput} from './dataGridFilterInputs';

export function getDateFilterOperators(showTime = false) {
  return [
    {
      value: 'is',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 === value2,
            showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: {showTime},
    },
    {
      value: 'not',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 !== value2,
            showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: {showTime},
    },
    {
      value: 'after',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 > value2,
            showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: {showTime},
    },
    {
      value: 'onOrAfter',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 >= value2,
            showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: {showTime},
    },
    {
      value: 'before',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 < value2,
            showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: {showTime},
    },
    {
      value: 'onOrBefore',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 <= value2,
            showTime,
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: {showTime},
    },
    {
      value: 'isEmpty',
      getApplyFilterFn: () => {
        return ({value}) => {
          return value == null;
        };
      },
      requiresFilterValue: false,
    },
    {
      value: 'isNotEmpty',
      getApplyFilterFn: () => {
        return ({value}) => {
          return value != null;
        };
      },
      requiresFilterValue: false,
    },
  ];
}

function buildApplyDateFilterFn(
    filterItem, compareFn, showTime = false) {
  if (!filterItem.value) {
    return null;
  }

  // Make a copy of the date to not reset the hours in the original object
  const filterValueCopy = new Date(filterItem.value);

  const filterValueMs = filterValueCopy.getTime();

  return (value) => {
    if (!value) {
      return false;
    }

    // Make a copy of the date to not reset the hours in the original object
    const dateCopy = new Date(value);

    dateCopy.setHours(
        showTime ? value.getHours() : 0,
        showTime ? value.getMinutes() : 0,
        0,
        0,
    );
    const cellValueMs = dateCopy.getTime();

    return compareFn(cellValueMs, filterValueMs);
  };
}

export function getSingleSelectFilterOperators(inputComponent) {
  return [
    {
      value: 'is',
      getApplyFilterFn: (filterItem) => {
        return buildApplySingleSelectFilterFn(
            filterItem,
            (value1, value2) => value1 === value2,
        );
      },
      InputComponent: inputComponent,
    },
    {
      value: 'not',
      getApplyFilterFn: (filterItem) => {
        return buildApplySingleSelectFilterFn(
            filterItem,
            (value1, value2) => value1 !== value2,
        );
      },
      InputComponent: inputComponent,
    },
    {
      value: 'isEmpty',
      getApplyFilterFn: () => {
        return ({value}) => {
          return value == null;
        };
      },
      requiresFilterValue: false,
    },
    {
      value: 'isNotEmpty',
      getApplyFilterFn: () => {
        return ({value}) => {
          return value != null;
        };
      },
      requiresFilterValue: false,
    },
  ];
}

function buildApplySingleSelectFilterFn(
    filterItem, compareFn) {

  const filterValue = filterItem.value;
    if (!filterValue) {
    return null;
  }
  return (value) => {

    if (!value) {
      return false;
    }
    return compareFn(value, filterValue);
  };
}

function getBookingTypeRowStatus({row}) {
  let status;
  const type = row.bookingType.name;
  const isReservationType = type === 'reservation' || type ===
      'recurrence';
  if (isReservationType) {
    status = row.status;
  } else {
    status = row.status === 'cancelled' ?
        'cancelled' :
        'active';
  }
  return status;
}

