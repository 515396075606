import {Box} from '@mui/material';
import useIsMobile from '../../../hooks/ui/useIsMobile';

function ContainerFullWidth({children}) {
  const isMobile = useIsMobile();
  return <Box
      sx={{
        margin: '0 auto',
        maxWidth: '100%',
        width: '100%',
        padding: isMobile?'0 12px':'0 24px',
      }}
  >
    {children}
  </Box>;
}

export default ContainerFullWidth;
