import {Box, Stack, Typography} from '@mui/material';
import {PAGE_HEADER_HEIGHT} from '../../../constants/ui';

function PageHeader({
  breadcrumbsSlot: BreadcrumbsSlot,
  actionsSlot: ActionsSlot,
  description,
}) {
  return (
      <Box sx={{
        display:"flex",
        width:"100%",
        alignItems:"center",
        height: PAGE_HEADER_HEIGHT,
      }}>
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{width:"100%"}}
        >
          <Stack spacing={'4px'}>
            <Box>
              {BreadcrumbsSlot}
            </Box>
            {
                description &&
                <Typography
                    color={'text.secondary'}
                >{description}</Typography>
            }
          </Stack>

          <Stack direction={'row'}
                 spacing={1}
                 sx={{ml: 'auto'}}
                 alignItems={'center'}>
            {ActionsSlot}
          </Stack>
        </Stack>
      </Box>
  );
}

export default PageHeader;
