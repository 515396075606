import {createTheme} from '@mui/material';

/*Default Palette*/
let theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
      dark: '#1E88E5',
      light: '#79D0FB',
    },
    secondary: {
      main: '#55e5e5',
      dark: '#43a3b2',
      light: '#78d7e7',
    },
    error: {
      main: '#EB495C',
      dark: '#A9244C',
      light: '#F37578',
    },
    warning: {
      main: '#FCBF07',
      dark: '#B57F03',
      light: '#FDD344',
    },
    info: {
      main: '#078FEA',
      dark: '#056FC9',
      light: '#9AE6FC',
    },
    success: {
      main: '#26B55D',
      dark: '#138253',
      light: '#55D279',
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      'A100': '#f5f5f5',
      'A200': '#eeeeee',
      'A400': '#bdbdbd',
      'A700': '#616161',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.55)',
      disabled: 'rgba(0, 0, 0, 0.28)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#fff',
    },
    action: {
      active: 'rgba(0,0,0,0.54)',
      hover: 'rgba(0,0,0,0.04)',
      selected: 'rgba(0,0,0,0.08)',
      disabled: 'rgba(0,0,0,0.26)',
      disabledBackground: 'rgba(0,0,0,0.12)',
      focus: 'rgba(0,0,0,0.12)',
    },
  },
});

/*Custom Palette*/
theme = createTheme(theme, {
  palette: {
    neutral: {
      primary: "#F2F5F7",
    },
    skeleton: 'rgba(0, 0, 0, 0.12)',
    icon: {
      primary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.3)',
    },
  },
});

/*Typography */
theme = createTheme(theme, {
  typography: {
    'body2': {color: theme.palette.text.secondary},
    "h7":{
      fontSize:"18px",
      fontWeight:"500",
    },
    h5:{
      fontSize:"24px",
      fontWeight:"400",
    },
    "h5-bold":{
      fontSize:"24px",
      fontWeight:"700",
    },
    h6:{
      fontSize:"20px",
      fontWeight:"500",
    },
    "h6-bold":{
      fontSize:"20px",
      fontWeight:"700",
    },
  },
});

/*Global Component Overrides*/
theme = createTheme(theme, {
  components: {
    MuiTypography:{
      defaultProps:{
        variantMapping:{
          // currently, you need to provide all of the default mapping because it will replace, not merge.
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h6",
          subtitle2: "h6",
          body1: "p",
          body2: "p",
          inherit: "p",
          // You have to add this line
          // otherwise the `span` (display `inline` by default)
          // cannot have margin.
          "h7": "h6",
          "h5-bold": "h5",
          "h6-bold": "h6",
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: ({ownerState}) => {

          const size = ownerState.size;
          const variant = ownerState.variant;

          let defaults = {
            textTransform: 'capitalize',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          };

          if (size === 'large') {
            if (variant === 'text') {
              return {
                ...defaults,
                padding: '9px 12px',
              };
            } else {
              return {
                ...defaults,
                padding: '9px 20px',
              };
            }
          }

          if (size === 'medium') {
            if (variant === 'text') {
              return {
                ...defaults,
                padding: '7px 8px',
              };
            } else {
              return {
                ...defaults,
                padding: '7px 16px',
              };
            }
          }

          if (size === 'small') {
            if (variant === 'text') {
              return {
                ...defaults,
                padding: '6px 6px',
              };
            } else {
              return {
                ...defaults,
                padding: '6px 12px',
              };
            }
          }

        },
      },
    },

    MuiMenu: {
      defaultProps: {
        elevation: 3,
      },
    },

    MuiSelect: {
      defaultProps: {
        MenuProps: {
          elevation: 3,
          disableScrollLock: false,
          MenuListProps: {
            sx: {
              maxHeight: '300px',
            },
          },
        },
      },
    },

    MuiModal: {
      defaultProps: {
        disableScrollLock: true,
      },
    },

    MuiDialogTitle: {
      defaultProps: {
        variant: 'h5',
        sx: {
          padding: '24px',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialog-paperWidthSm': {
            borderRadius: '12px',
            width: '600px',
          },
          '.MuiDialog-paperWidthXl': {
            borderRadius: '12px'
          }
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 26px',
          borderBottom: 'none',
        },
      },
      defaultProps: {
        dividers: true,
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: '20px',
          paddingBottom: '24px',
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      },
    },

    MuiPickersPopper: {
      defaultProps: {
        PaperProps: {
          elevation: 3,
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          '.MuiMenuItem-root': {
            '.MuiTouchRipple-child': {
              backgroundColor: theme.palette.primary.light,
            },
            '&.Mui-selected': {
              '.MuiTouchRipple-child': {},
              '&:hover': {},
            },
            '&:focus': {},
            '&:hover': {},
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            '& fieldset': {},
            '&:hover': {
              '& fieldset': {},
            },
          },
          '&.Mui-focused': {
            '&.MuiOutlinedInput-root': {
              '& fieldset': {},
              '&:hover': {
                '& fieldset': {},
              },
            },
          },
        },
      },
    },

    /*Buttons*/

    /*Data Grid*/
    MuiDataGrid: {
      styleOverrides: {
        root: {

          borderStyle:"none",

          '.MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.grey[50],
            '.MuiDataGrid-columnHeaderTitleContainer': {
              color: theme.palette.grey[800],
            },
          },

          'div[class*=--pinnedLeft]':{
            borderRight:'none',
            backgroundColor:'inherit',

            [theme.breakpoints.down('md')]: {
              backgroundColor:'#fff'
            },
          },
         
          '.MuiDataGrid-row': {
            '.MuiDataGrid-cell': {
              color: theme.palette.text.primary,
            },
            '&:last-child': {
              '.MuiDataGrid-cell': {
              },
            },
          },
          '.MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within,.MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '.MuiTablePagination-root': {
            height: '99%',
            overflow: 'hidden',
          },
          '.MuiDataGrid-toolbarContainer': {
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          '.MuiDataGrid-footerContainer': {
            minHeight: '64px',
            '.MuiTablePagination-root': {
              display: 'flex',
              alignItems: 'center',
            },
          },

        },
      },
    },

    /*Table*/
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        body: {
          borderColor: theme.palette.divider,
          ':first-of-type': {
          },
          ':last-of-type': {
          },
        },
        head: {
          backgroundColor: theme.palette.grey[50],
          ':first-of-type': {
            borderTopLeftRadius: '4px',
          },
          ':last-of-type': {
            borderTopRightRadius: '4px',
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            position: 'relative',
            background: '#fff',
            '&:hover': {
              background: theme.palette.grey['50'],
            },
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          borderTop: '0',
        },
      },
    },

    /*Chip*/
    MuiChip: {
      styleOverrides: {
        root: {
          display: "inline-flex",
          justifyContent:"center",
          alignItems: "center",
          '.MuiChip-label': {
            display: "flex",
            justifyContent:"center",
            alignItems: "center",
            overflow: 'visible',
            lineHeight:1,
          },
          textTransform: 'capitalize',
        },
      },
    },

    /*Skeleton*/
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },

    MuiTab:{
      styleOverrides:{
        root:{
          textTransform:"capitalize"
        }
      }
    }

  },

});

export default theme;
