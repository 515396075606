import {Divider, List, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'


const LayoutMainMobileNavbarSubMenu=props=>{

    const {items} = props

    return(
        <List
        component={'ul'}
        sx={{
            p:0
        }}
        >
           {
            items.map((item, i) => {
            if (item.canAccess) {
                return <>
                <ListItemButton
                    key={i}
                    component={NavLink}
                    to={item.route}
                    disabled={item.disabled}
                    sx={{
                    padding:'8px 25px',
                    listStyle:'none',
                    '&.active, &.active:hover': {
                        backgroundColor: '#D9EAFF',
                    },
                    '.text': {
                        fontSize: '14px',
                    },
                    }}
                >
                <ListItemText
                    disableTypography
                    className={'text'}
                >
                    {item.name}
                </ListItemText>
                </ListItemButton>
                <Divider/>
                </>;
            }
            })
            }
        </List>
    )
}
export default LayoutMainMobileNavbarSubMenu