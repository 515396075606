import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {DatePicker, DateTimePicker} from '@mui/x-date-pickers';

export function GridFilterReservationStatusSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Status'} {...props}>
    <MenuItem value={'paid'}>
      {t('global.chips.label.paid')}
    </MenuItem>
    <MenuItem value={'unpaid'}>
      {t('global.types.status.unpaid')}
    </MenuItem>
    <MenuItem value={'cancelled'}>
      {t('global.types.status.cancelled')}
    </MenuItem>
    <MenuItem value={'complete'}>
      {t('global.types.status.complete')}
    </MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterBookingsStatusSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Status'} {...props}>
    <MenuItem value={'paid'}>
      {t('global.chips.label.paid')}
    </MenuItem>
    <MenuItem value={'unpaid'}>
      {t('global.types.status.unpaid')}
    </MenuItem>
    <MenuItem value={'cancelled'}>
      {t('global.types.status.cancelled')}
    </MenuItem>
    <MenuItem value={'active'}>
      {t('global.types.status.active')}
    </MenuItem>
    <MenuItem value={'complete'}>
      {t('global.types.status.complete')}
    </MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterRecurringBookingsStatusSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Status'} {...props}>
    <MenuItem value={'paid'}>
      {t('global.chips.label.paid')}
    </MenuItem>
    <MenuItem value={'unpaid'}>
      {t('global.types.status.unpaid')}
    </MenuItem>
    <MenuItem value={'cancelled'}>
      {t('global.types.status.cancelled')}
    </MenuItem>
    <MenuItem value={'complete'}>
      {t('global.types.status.complete')}
    </MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterSportSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Sport'} {...props}>
    <MenuItem value={'Padel'}>{t('global.types.sports.Padel')}</MenuItem>
    <MenuItem value={'Tennis'}>{t('global.types.sports.Tennis')}</MenuItem>
    <MenuItem value={'Squash'}>{t('global.types.sports.Squash')}</MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterCourtFeatureSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Feature'} {...props}>
    <MenuItem value={'Natural Grass'}>{t('global.types.courtFeatures.Natural Grass')}</MenuItem>
    <MenuItem value={'Synthetic Grass'}>{t('global.types.courtFeatures.Synthetic Grass')}</MenuItem>
    <MenuItem value={'Clay'}>{t('global.types.courtFeatures.Clay')}</MenuItem>
    <MenuItem value={'Quick'}>{t('global.types.courtFeatures.Quick')}</MenuItem>
    <MenuItem value={'Crystal'}>{t('global.types.courtFeatures.Crystal')}</MenuItem>
    <MenuItem value={'Wall'}>{t('global.types.courtFeatures.Wall')}</MenuItem>
    <MenuItem value={'Panoramic'}>{t('global.types.courtFeatures.Panoramic')}</MenuItem>
    <MenuItem value={'Concrete'}>{t('global.types.courtFeatures.Concrete')}</MenuItem>
    <MenuItem value={'Carpet'}>{t('global.types.courtFeatures.Carpet')}</MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterCourtTypeSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Feature'} {...props}>
    <MenuItem value={'Indoor'}>{t('global.types.courtTypes.Indoor')}</MenuItem>
    <MenuItem value={'Outdoor'}>{t('global.types.courtTypes.Outdoor')}</MenuItem>
    <MenuItem value={'Roofed Indoor'}>{t('global.types.courtTypes.Roofed Indoor')}</MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterEventTypeSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Event Type'} {...props}>
    <MenuItem value={'Invite Only'}>{t(
        'global.types.events.eventTypes.Invite Only')}</MenuItem>
    <MenuItem value={'Public'}>{t(
        'global.types.events.eventTypes.Public')}</MenuItem>
        <MenuItem value={'Competition'}>{t(
        'global.types.events.eventTypes.Competition')}</MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterEventStatusSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Event Status'} {...props}>
    <MenuItem value={'Published'}>{t(
        'global.types.events.status.Published')}</MenuItem>
    <MenuItem value={'Unpublished'}>{t(
        'global.types.events.status.Unpublished')}</MenuItem>
    <MenuItem value={'Closed'}>{t(
        'global.types.events.status.Closed')}</MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterBookingTypeSelect(props) {
  const {t} = useTranslate();
  return (
      <GridFilterSelectBase
          label={'Type'}
          {...props}
      >
        <MenuItem value={'reservation'}>{t(
            'global.types.bookings.reservation')}</MenuItem>
        <MenuItem value={'recurrence'}>{t(
            'global.types.bookings.recurrence')}</MenuItem>
        <MenuItem value={'event'}>{t('global.types.bookings.event')}</MenuItem>
        <MenuItem value={'maintenance'}>{t(
            'global.types.bookings.maintenance')}</MenuItem>
        <MenuItem value={'closed'}>{t(
            'global.types.bookings.closed')}</MenuItem>
      </GridFilterSelectBase>
  );
}

export function GridFilterReservationTypeSelect(props) {
  const {t} = useTranslate();
  return (
      <GridFilterSelectBase
          label={'Type'}
          {...props}
      >
        <MenuItem value={'reservation'}>{t(
            'global.types.bookings.reservation')}</MenuItem>
        <MenuItem value={'recurrence'}>{t(
            'global.types.bookings.recurrence')}</MenuItem>
      </GridFilterSelectBase>
  );
}

export function GridFilterRecurrenceTypeSelect(props) {
  const {t} = useTranslate();
  return (
      <GridFilterSelectBase
          label={'Recurrence'}
          {...props}
      >
        <MenuItem value={'daily'}>
          {t('global.types.recurrenceTypes.daily')}
        </MenuItem>
        <MenuItem value={'weekly'}>
          {t('global.types.recurrenceTypes.weekly')}
        </MenuItem>
        <MenuItem value={'biweekly'}>
          {t('global.types.recurrenceTypes.biweekly')}
        </MenuItem>
        <MenuItem value={'monthly'}>
          {t('global.types.recurrenceTypes.monthly')}
        </MenuItem>
      </GridFilterSelectBase>
  );
}

export function GridFilterWalletTransactionTypeSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Transaction Type'} {...props}>
    <MenuItem value={'booking_creation'}>{t(
        'global.types.transactions.booking_creation')}</MenuItem>
    <MenuItem value={'offer_purchase'}>{t(
        'global.types.transactions.offer_purchase')}</MenuItem>
    <MenuItem value={'refund'}>{t(
        'global.types.transactions.refund')}</MenuItem>
         <MenuItem value={'event_refund'}>{t(
        'global.types.transactions.event_refund')}</MenuItem>
        <MenuItem value={'event_registration'}>{t(
        'global.types.transactions.event_registration')}</MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterTransactionPaymentTypeSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Transaction Type'} {...props}>
    <MenuItem value={'debit'}>
      {t('global.types.paymentTypes.debit')}
    </MenuItem>
    <MenuItem value={'credit'}>
      {t('global.types.paymentTypes.credit')}
    </MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterFinancesTransactionStatusSelect(props) {
  const {t} = useTranslate();
  return (
      <GridFilterSelectBase
          label={'Status'}
          {...props}
      >
        <MenuItem value={t('global.types.finances.transactions.status.open')}>
          {t('global.types.finances.transactions.status.open')}
        </MenuItem>
        <MenuItem value={t('global.types.transactions.status.paid')}>
          {t('global.types.transactions.status.paid')}
        </MenuItem>
        <MenuItem value={t('global.types.transactions.status.failed')}>
          {t('global.types.transactions.status.failed')}
        </MenuItem>
        <MenuItem value={t('global.types.transactions.status.refunded')}>
          {t('global.types.transactions.status.refunded')}
        </MenuItem>
        <MenuItem value={t('global.types.transactions.status.expired')}>
          {t('global.types.transactions.status.expired')}
        </MenuItem>
        <MenuItem value={t('global.types.transactions.status.refund-initiated')}>
          {t('global.types.transactions.status.refund-initiated')}
        </MenuItem>
       

      </GridFilterSelectBase>
  );
}

export function GridFilterFinancesTransactionPaymentGatewaySelect(props) {
  return (
      <GridFilterSelectBase
          label={'Payment Gateway'}
          {...props}
      >
        <MenuItem value={'mollie'}>
          Mollie
        </MenuItem>
      </GridFilterSelectBase>
  );
}

export function GridFilterFinancesTransactionTypeSelect(props) {
  return (
      <GridFilterSelectBase
          label={'Payment Gateway'}
          {...props}
      >
        <MenuItem value={'order'}>
        Order
        </MenuItem>
        <MenuItem value={'recurr'}>
        Recurring
        </MenuItem>
        <MenuItem value={'event'}>
        Event
        </MenuItem>
        <MenuItem value={'offer'}>
        Offer
        </MenuItem>
      </GridFilterSelectBase>
  );
}

export function GridFilterAdminTypeSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Feature'} {...props}>
    <MenuItem value={'Club Staff'}>{t('global.types.adminTypes.Club Staff')}</MenuItem>
    <MenuItem value={'Club Admin'}>{t('global.types.adminTypes.Club Admin')}</MenuItem>
    <MenuItem value={'Company Admin'}>{t('global.types.adminTypes.Company Admin')}</MenuItem>

  </GridFilterSelectBase>;
}

export function GridFilterPackageVisibility(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Visibility'} {...props}>
    <MenuItem value={'true'}>{t(`global.breadcrumbs.public`)}</MenuItem>
    <MenuItem value={'false'}>{t('packages.create.statusPrivate')}</MenuItem>

  </GridFilterSelectBase>;
}

export function GridFilterClubStatus(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Visibility'} {...props}>
    <MenuItem value={'true'}>{t(`bookings.list.tabs.active`)}</MenuItem>
    <MenuItem value={'false'}>{t('global.chips.label.inactive')}</MenuItem>

  </GridFilterSelectBase>;
}

export function GridFilterEnabledDisabledSelect(props) {
  const {t} = useTranslate();
  return <GridFilterSelectBase label={'Transaction Type'} {...props}>
    <MenuItem value={'enabled'}>
      {t('global.types.enabledDisabled.enabled')}
    </MenuItem>
    <MenuItem value={'disabled'}>
      {t('global.types.enabledDisabled.disabled')}
    </MenuItem>
  </GridFilterSelectBase>;
}

export function GridFilterDateInput(props) {
  const {item, showTime, applyValue, apiRef} = props;

  const Component = showTime ? DateTimePicker : DatePicker;
  const handleFilterChange = (newValue) => {
    applyValue({...item, value: newValue});
  };

  return (
      <Component
          value={item.value || null}
          autoFocus
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'small',
              sx: {
                width: '100%',
              },
            },
            inputAdornment: {
              sx: {
                '& .MuiButtonBase-root': {
                  marginRight: -1,
                },
              },
            },
          }}
          onChange={handleFilterChange}
      />
  );
}

function GridFilterSelectBase({label = 'Value', ...rest}) {

  const {item, applyValue, focusElementRef} = rest;

  return <FormControl
      size={'small'}
  >
    <InputLabel shrink={true}>{label}</InputLabel>
    <Select
        notched
        label={label}
        variant={'outlined'}
        value={item.value || ''}
        onChange={(e) => {
          applyValue({...item, value: e.target.value});
        }}

    >
      {rest.children}
    </Select>
  </FormControl>;
}
